
<template>
  <div>
    <h2>正在创建</h2>
    <!-- <h1>authCallback</h1> -->
    <!-- <button @click="goto">下一页</button> -->
  </div>
</template>
<script>
import {wxworkRegister} from '@/api/user.js'
export default {
  data(){
    return{
 
    }
  },
  created(){
    if(this.$route.query.wecomCorpId){
      this.register()
    }
  },
  methods:{
    async register(){
      const data={
        username: this.$route.query.corp_name,
        password: 123456,
        wecomCorpId: this.$route.query.wecomCorpId,
        wecomUserId: this.$route.query.wecomUserId
      }
      const res = await wxworkRegister(data)
      await this.$store.dispatch('wxSetToken', res.data.token)
      this.$toast.success('创建成功')
      this.$router.push('/home')
    }
  }
}
</script>
<style lang='less' scoped>
</style>